import CredentialVerifier from '../pages/CredentialVerifier'

const VerifierLayout = () => {
  
  return (
    <div
      style={{
        height: '100vh',
      }}
    >

      <CredentialVerifier />
    </div>
  )
}

export default VerifierLayout
